.ag-theme-balham {
    font-family: 'Telegraf','IBM Plex Sans' !important;

}

.ag-theme-deepcell {
    --ag-grid-size: 20px;
    --ag-foreground-color: #172623;
    --ag-background-color: #FFF;
    --ag-header-foreground-color: #5F55D1;
    --ag-header-background-color: #E3FCEE;
    --ag-header-column-resize-handle-color: #FFF;
    --ag-balham-active-color: #5F55D1;
    --ag-border-color: #C4C6FC;
    --ag-input-border-color: #8B8FF1;
}

.lock-pinned {
    background-color: #E3FCEE;
    color: #5F55D1;
}