@font-face {
    font-family: Telegraf;
    src: url('../public/fonts/PPTelegraf-Regular.ttf');
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Telegraf', 'Poppins',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    max-height: 100%;
    margin: 0;
    overflow: hidden;
    font-family: 'Telegraf', 'IBM Plex Sans';
}
a {
    color: #5f55d1;
}

.lock-pinned {
    background: #ddd;
}
.js-plotly-plot .plotly .modebar-group {
    padding-left: 0px !important;
    font-size: 14px !important;
    background-color: rgba(255, 255, 255) !important;
}

.deepcell-bg-logo {
    background-image: url('../public/images/watermark.png'),
        url('../public/images/watermark-reverse.png');
    background-repeat: no-repeat no-repeat;
    background-position: 0 0%, 100% 99.84%;
}
